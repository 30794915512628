.languageName {
    position: relative;
    margin-left: 24px;
}
.languageName .selectData :global .ant-btn .value .select-arrow {
    margin-left: 0;
}
.btnText {
    font-size: 14px;
    color: #8E939F;
    position: absolute;
    bottom: 8px;
    left: 10px;
    z-index: 1;
}
.selectData {
    width: 190px !important;
}
.selectData :global .ant-btn .value-space-between {
    padding-left: 63px;
}
.languageName .selectData :global .ant-btn .value .select-arrow {
    margin-left: 0;
}
.selectData :global .ant-btn {
    width: 100%;
    text-align: right;
    /* background: #211E49 !important;
    border: 1px solid #211E49!important; */
    border-radius: 8px !important;
    font-size: 14px;
    /* color: #FFF; */
}
.selectData :global .ant-btn .valueText {
    /* color: #fff; */
    padding-right: 4px;
}
/* .selectData :global .ant-popover-inner {
    background: #211E49 !important;
} */
.selectData :global .ant-popover-inner .content{
    color: #8E939F;
}
.selectData :global .kiki-select-Popover__container .menu-wrap .menu-item {
    position: relative;
}
.selectData :global .kiki-select-Popover__container .menu-wrap .menu-item:hover {
    background-color: transparent;
}
.selectData :global .kiki-select-Popover__container .menu-wrap .menu-item::after{
    content: '';
    position: absolute;
    width: calc(100% - 16px);
    border-radius: 4px;
    height: 100%;
    left: 8px;
}
.selectData :global .kiki-select-Popover__container .menu-wrap .menu-item:hover * {
    z-index: 3;
}
@media screen and (max-width: 960px) {
    .languageName {
        margin-left: 0;
    }
}
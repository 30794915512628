@font-face {
   font-family: "DINPro";
   src: url("/fonts/DINPro-Regular.otf")
}

@font-face {
   font-family: "DINPro-Regular";
   src: url("/fonts/DINPro-Regular.otf")
}

@font-face {
   font-family: "DINPro-Bold";
   src: url("/fonts/DINPro-Bold.otf")
}

@font-face {
   font-family: "DINPro-Medium";
   src: url("/fonts/DINPro-Medium.otf")
}

html[lang='en'] * {
   font-family: DINPro;
}

html[lang="zh-TC"] *,
html[lang="zh-CN"] * {
   font-family: PingFangSC-Regular, arial, GlowSansSC, SourceHanSansCN;
}



html[lang="zh-TC"] .PingFangSC-Semibold,
html[lang="zh-CN"] .PingFangSC-Semibold,
html[lang="zh-CN"] .Bold {
   font-family: PingFangSC-Semibold;
   font-weight: 700;
}

html[lang="zh-TC"] .PingFangSC-Medium,
html[lang="zh-CN"] .PingFangSC-Medium,
html[lang="zh-CN"] .Medium,
html[lang="zh-TC"] .Medium {
   font-family: PingFangSC-Medium;
   font-weight: 500;
}

html .DINPro-Medium,
html[lang='en'] .Medium {
   font-family: DINPro-Medium;
}

html .DINPro-Bold,
html[lang='en'] .Bold {
   font-family: DINPro-Bold;
}

.DINPro {
   font-family: DINPro-Regular !important;
   font-weight: 400;
}

.fontMedium {
   font-family: DINPro-Medium, PingFangSC-Medium !important;
}

.fontBold {
   font-family: DINPro-Bold, PingFangSC-Semibold !important;
}
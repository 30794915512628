.noLoginTip{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    cursor: pointer;
}

.noLoginTip span:first-child,.noLoginTip span:nth-child(3){
    color: #6F40EE;
}

.noLoginTip span:first-child{
    margin-right: 5px;
}
.noLoginTip span:nth-child(3){
    margin: 0 5px;
}
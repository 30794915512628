.text-align-right {
	text-align: right;
}
.rise-price{
  color: #1EAE45;
}
.fall-price{
	color:  #ED220D;
}
.flat-price {
	color:  #8E939F;
}
.text-align-center {
	text-align: center;
}

.copyright {
	background-color: #f6f8fb;
	text-align: center;
	font-size: 12px;
	color: #8E939F;
}

@media screen and (max-width: 960px) {
	.copyright {
		white-space: pre-line;
	}
}
.sunscribe {
    position: relative;
    background: #090636;
}
.subscribeInfo {
    width: 540px;
    position: absolute;
    top: 100px;
    left: 50%;
    margin-left: -270px;
    text-align: center;
}
.subscribeTitle {
    font-size: 48px;
    color: #FFF;
}
.newInformation {
    font-size: 24px;
    color: #FFF;
    margin-bottom: 20px;
}
.useSubscribe :global .kiki-startUse-main {
    width: 100%;
    display: flex;
    height: 72px;
}
.useSubscribe :global .kiki-startUse-main .kiki-input.ant-input {
    padding: 0 30px;
    border-radius: 6px 0 0 6px;
    font-size: 21px;
    flex: 1;
    background: #A496BB;
    color: #fff;
}
.useSubscribe :global .kiki-startUse-main .ant-btn {
    width: 180px;
    font-size: 21px;
    margin-top: 0;
    border-radius:0 6px 6px 0 !important;
}
.useSubscribe :global .kiki-startUse-main input::-webkit-input-placeholder {
    color: #fff;
}
@media screen and (max-width: 960px){
    .sunscribe div span {
        height: 513px !important;
    }
    .sunscribe div span img {
        height: 513px !important;
    }
    .subscribeInfo {
        padding:  0 15px;
        width: 100%;
        top:60px;
        left: 0;
        margin-left: 0;
    }
    .subscribeTitle {
        font-size: 24px;
    }
    .newInformation {
        font-size:12px;
        color: #FFF;
        margin:8px 0 20px 0;
    }
    .useSubscribe :global .kiki-startUse-main {
        display: block;
    }
    .useSubscribe :global .kiki-startUse-main .kiki-input.ant-input {
        flex: none;
        height: 48px;
        font-size: 14px;
        border-radius: 8px !important;
    }
    .useSubscribe :global .kiki-startUse-main .ant-btn {
        width: 100%;
        height: 48px;
        border-radius: 8px !important;
        font-size: 14px;
        margin-top: 24px;
        line-height: 48px;
    }
}
